import MockAdapter from 'axios-mock-adapter';
import fakeData from '@/@fake-db/data/db.json';
import BackendClient from '../remote/BackendClient';

const MOCKED_FIELDS = [];

export default class MockableBackendClient extends BackendClient {
  constructor(options) {
    super(options);

    const mock = new MockAdapter(this.client, { delayResponse: 250 });

    MOCKED_FIELDS.forEach((itemType) => {
      mock.onGet(`${itemType}`).reply(fakeData[itemType]);
    });

    // An example on how to mock the api for the test 'foo' app
    // mock.onGet(/foo\/[\w\d-]+/).reply((request) => {
    //   const match = /foo\/([\w\d-]+)/.exec(request.url || '');
    //   if (!match || !match[1]) {
    //     return [500];
    //   }

    //   const id = match[1];

    //   return [200, `Foo with id ${id}`];
    // });

    mock.onAny().passThrough();
  }
}
